import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ActionBasedLearningConsulting = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Intuitive Eating.png" }) {
        childImageSharp {
          fixed(width: 382, height: 448) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Fitness and Spirit- Intuitive Eating" />
      <div className="container-main">
        <div className="container-main_div">
          <h1 className="text-center">Intuitive Eating</h1>
          <div>
            <Img
              fixed={data.file.childImageSharp.fixed}
              style={{
                margin: '40px auto',
                textAlign: 'center',
                display: 'block',
              }}
            />
          </div>

          <p>
            In America, our obesity numbers of both children and adults,
            continue to rise. Chronic diseases are becoming the norm and dieting
            is always a hot topic! Our perception of what is healthy has become
            distorted, and mostly intangible by natural means. With that being
            said, my own history of my relationship with food has had its share
            of battles and learning curves!
          </p>
          <p>
            So, along my journey of learning through my own life experiences and
            my studies on nutrition, there are a few foundational tools that I
            continue to return to. I believe, regardless of our ever evolving
            wisdom about nutrition you can always fall back on the following for
            your foundation: the five food groups, portion control, moderation,
            mindfulness, and being intune with what your body is communicating
            to you. Along with that, I share with you valuable information I
            pulled from a great book called, Intuitive Eating by Evelyn Tribole.
          </p>
          <p>
            “Mindsight” Humans are privileged to experience a dynamic interplay
            of instinct, emotion, and thought, which work together to
            orchestrate life, and are mediated by the brain. (Daniel Siegel)
          </p>

          <p>
            Our reptilian brain is where our instincts derive from. Our limbic
            brain is where our emotions and social behaviors originate. And, our
            rational brain, the neocortex integrates the two to perceive the
            instinctual and feeling parts of our beings and reflects upon them.
          </p>

          <p>
            Intuitive Eating is about trusting that you will be able to access
            all the information you need to have, by using all aspects of your
            brain-your reptilian instincts, your limbic connection with your
            emotions, and your rational thoughts.
          </p>

          <p>
            Historically, 90-95% of all diets fail, however, people continue to
            blame themselves for failing, and not the process of dieting. This
            ongoing failure with yo-yo dieting creates blame, shame, negativity,
            and guilt. To bridge the gap between the anti-diet movement and the
            health community, Intuitive Eating was created. The anti-dieting
            movement hails body acceptance, yet it fails to address health
            risks. IE is a new way of eating that is struggle-free and healthy
            for your mind and body. It is trusting your body and it’s signals.
            IE will not only change your relationship with food; it may change
            your life!
          </p>

          <h2>Mindful Eating Vs. Intuitive Eating</h2>

          <ul>
            <li>
              Mindful Eating is allowing yourself to be aware of the positive
              and nurturing opportunities that are available through food
              preparation and consumption, by respecting your own inner voice.
            </li>
            <li>
              ME is choosing to eat food that is both pleasing and nourishing to
              your body, by using all your senses to explore, savor, and taste.
            </li>
            <li>ME is acknowledging responses to food without judgment.</li>
            <li>
              ME is learning to be aware of physical hunger and satiety cues to
              decide when to begin and stop eating.
            </li>
          </ul>

          <p>
            Intuitive Eating includes all the principles of Mindful Eating and
            also encompasses a broader philosophy, addressing the issues of
            cognitive distortions and emotional eating. IE is a dynamic process-
            integrating attunement of mind, body and food.
          </p>
          <h2>Dieting Increases your Risk for Gaining Weight</h2>
          <p>
            Dieting becomes an obsession about food and body. Food becomes your
            enemy, guilt and shame set in when your restrictions are not met, it
            slows down your metabolism, and it becomes a vicious cycle of
            discomfort in your own body.
          </p>

          <p>
            Dieting is like the Chinese finger puzzle. The harder you try to get
            out, the more pressure you exert, the more difficult it is to get
            out of the puzzle. Instead you find yourself locked in tighter,
            trapped and frustrated. (John Foreyt) As the cumulative side effects
            of dieting you may experience the following Diet Backlash:
          </p>

          <ul>
            <li>Contemplation of dieting brings on urges and cravings.</li>
            <li>Upon ending a diet, you go on a food binge and feel guilty.</li>
            <li>Having little trust in self with food.</li>
            <li>Feeling that you don’t deserve to eat.</li>
            <li>Shortened dieting duration.</li>
            <li>
              The Last Supper consuming foods you presume you won’t eat again.
            </li>
            <li>
              Social withdrawal often involves fearing not being able to stay in
              control.
            </li>
            <li>
              Sluggish metabolism teaching the body to adapt for self-imposed
              famine.
            </li>
            <li>Using caffeine to survive the day.</li>
            <li>Eating Disorders</li>
          </ul>
          <p className="mt-5">
            Achieving a good balanced nutrition and providing our bodies with
            what it requires is a process. There will be a push and pull,
            failures and successes, and highs and lows along our journey. May
            you persevere, listen to your body, seek understanding, practice
            self-control and love yourself.
          </p>
        </div>
      </div>
    </Layout>
  )
}
export default ActionBasedLearningConsulting
